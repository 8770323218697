<!-- header -->
<header class="site-header header dark d-block d-md-none">
    <!-- main header -->
    <div class="sticky-header main-bar-wraper navbar-expand-lg">
        <div class="main-bar clearfix">
            <div class="container-fluid clearfix">
                <!-- website logo -->
                <div class="logo-header">
                    <a [routerLink] = "''"><img src="assets/images/logo-2.png" alt=""></a>
                </div>
                <!-- extra nav -->
                <div class="extra-nav">
                    <div class="extra-cell">
                        <ul class="header-social">
                            <li><a href="javascript:void(0);" class="openbtn navicon" ><span></span><span></span><span></span></a></li>
                        </ul>
                    </div>
                </div>
             </div>
        </div>
    </div>
    <!-- main header END -->
</header>
<!-- header END -->
