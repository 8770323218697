//import { Component, OnInit } from '@angular/core';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, Params } from "@angular/router";
import {ReservationFormService} from './reservation-form.service';
import { DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms'; 
import { ReactiveFormsModule } from '@angular/forms';


const now = new Date();

@Component({
  selector: 'app-reservation-form',
  templateUrl: './reservation-form.component.html',
  styleUrls: ['./reservation-form.component.css']
})
export class ReservationFormComponent implements OnInit {


  ReservationModel: any  = {};
  dzDate;

  
  constructor(  private _Route: Router,
    private _routeParams: ActivatedRoute,
    private reservationFormService: ReservationFormService) { 
    

  }

  ngOnInit(): void {
    
    this.dzDate = { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate() };
    //this.ref.markForCheck();//If not, Check box not working properly
    //alert(JSON.stringify(this.dzDate))

  }

  dateFormateChange1(datestring: any) {
    var date = '';
    var year = String(this.dzDate.year);
    var month = String(this.dzDate.month).padStart(2, '0');;
    var day = String(this.dzDate.day).padStart(2, '0');;
    date = year +"/"+ month + "/" + day;

    return date;
}


  SendEmail(){

   

    //undefined

    if(this.ReservationModel.dzName == undefined){
      alert(JSON.stringify('Votre nom est obligatoire!'))
      return;
    }

    if(this.ReservationModel.dzName == ''){
      alert(JSON.stringify('Votre nom est obligatoire!'))
      return;
    }

    if(this.ReservationModel.dzEmail == undefined){
      alert(JSON.stringify('Votre e-mail est obligatoire!'))
      return;
    }

    if(this.ReservationModel.dzEmail == ''){
      alert(JSON.stringify('Votre e-mail est obligatoire!'))
      return;
    }

    if(this.ReservationModel.dzTpNum == undefined){
      alert(JSON.stringify('Veuillez entrer votre numéro de contact!'))
      return;
    }

    if(this.ReservationModel.dzTpNum == ''){
      alert(JSON.stringify('Veuillez entrer votre numéro de contact!'))
      return;
    }


     alert(JSON.stringify(this.ReservationModel.dzDate))

    if(this.dzDate == undefined){
      alert(JSON.stringify('Date!'))
      return;
    }

    if(this.dzDate == ''){
      alert(JSON.stringify('Date!'))
      return;
    }

    //[ngModelOptions]="{standalone: true}"

    let dzName = this.ReservationModel.dzName;
    let dzEmail =this.ReservationModel.dzEmail;
    let dzTpNum = this.ReservationModel.dzTpNum;
    let dzPerson =this.ReservationModel.dzPerson;
    let dzDate =this.dateFormateChange1(this.dzDate);
    let dzTime =this.ReservationModel.dzTime;
    let dzMessage =this.ReservationModel.dzMessage;


    alert(JSON.stringify('Reservation submited for approval!'))

/*     this.reservationFormService.sendMail(dzName,dzEmail,dzPerson,dzDate,dzTime,dzMessage).subscribe((result: any) => {
 
      alert(JSON.stringify('Reservation submited for approval!'))
   
   }
 
   ); */


  }


}
