<!-- Menu -->
<div class="section-full content-inner-2 overlay-white-light" style="background-image:url(assets/images/background/bg1.jpg); background-position: top right; background-repeat: no-repeat;">
    <div class="container">
       <div class="section-head text-center">
           <h3 class="title">AVEZ VOUS DÉJÀ ESSAYÉ ?</h3>
           <h4 class="sub-title">CE SONT NOS SPÉCIAUX :</h4>
           <div class="dlab-separator"></div>
       </div>
       <div class="row">
           <div class="col-lg-12">
               <div class="menu-box">
                   <ul class="menu-list">
                       <li *ngFor = "let item of menuList let key = index">
                           <div class="item-left">
                               <img src="assets/images/cup/{{item.image}}" alt=""/>
                               <h4 class="title">{{item.title}}  <i *ngIf="item.veg" class='fa fa-leaf' style='font-size:22px;color:green'></i></h4>
                               <p class="description">{{item.description}}</p>
                           </div>
                           <div class="item-right">
                               <h5 class="price">€{{item.price}}</h5>
                           </div>
                       </li>
                   </ul>
               </div>
           </div>
       </div>
   </div>
</div>
<!-- Menu End -->
