import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-section',
  templateUrl: './info-section.component.html',
  styleUrls: ['./info-section.component.css']
})
export class InfoSectionComponent implements OnInit {

  gallery = [
    {
      image: "pic1.jpg",
    },
    {
      image: "pic2.jpg",
    },
    {
      image: "pic3.jpg",
    },
    {
      image: "pic4.jpg",
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
