<!-- About Services -->
<div class="section-full bg-img-fix content-inner overlay-black-middle" style="background-image:url(assets/images/background/bg2.jpeg);">
    <div class="container">
        <div class="section-head text-center">
            <h3 class="title text-white">LE SAVIEZ-VOUS?</h3>
            <h4 class="sub-title">À PROPOS DE NOTRE RESTAURANT :</h4>
        </div>
        <div class="row">
            <div *ngFor="let item of service" class="col-lg-3 col-md-3 col-sm-6 col-6 m-b30">
                <div class="icon-bx-wraper center text-white service-box">
                    <div class="icon-bx-xl"> 
                        <span class="icon-cell"><img src="assets/images/icons/{{item.image}}" alt=""></span> 
                    </div>
                    <div class="icon-content">
                        <h5 class="dlab-tilte">{{item.title}}</h5>
                    </div>
                    <div class="icon-content">
                        <h5 class="dlab-tilte">{{item.desc1}}</h5>
                    </div>
                    <div class="icon-content">
                        <h5 class="dlab-tilte">{{item.desc2}}</h5>
                    </div>
                    <div class="icon-content">
                        <h5 class="dlab-tilte">{{item.desc3}}</h5>
                    </div>
                    <div class="icon-content">
                        <h5 class="dlab-tilte">{{item.desc4}}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- About Services END -->
