<!-- Owner -->
<div class="section-full bg-img-fix content-inner-2 overlay-black-middle" style="background-image:url(assets/images/background/bg3.jpeg);">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="owner-profile text-center text-white">
                    <div class="owner-profile-info">
                       <!--  <div class="owner-profile-pic">
                            <img src="assets/images/testimonials/pic2.jpg" alt="">
                        </div> -->
                        <div class="owner-profile-content">
                            <h2 class="owner-name">Notre saveur</h2>
                            <!-- <h5 class="owner-position text-primary">RESTAURANT OWNER</h5> -->
                            <p>Saveur indienne que tout le monde aime! Toute notre cuisine est faite maison, avec des produits frais qui arrivent tous les jours. Et nous assurons la qualité de nos produits </p>
                            <!-- <p class="owner-signature">Jonathan Blair</p> -->
                        </div>
                    </div>	
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Owner END -->
