<!-- Menu -->
<div class="section-full content-inner-2" style="background-image:url(assets/images/background/bg1.jpg); background-position: top right; background-repeat: no-repeat;">
    <div class="container">
       <div class="section-head text-center">
           <h3 class="title">NOTRE CARTE</h3>
           <h4 class="sub-title">   15 % de remise à EMPORTER</h4>
       </div>
       <div class="dlab-tabs menu-tabs">
           <ul class="nav nav-tabs">
               <li *ngFor="let item of menuList let key = index">
                   <a data-toggle="tab" href="#{{item.category_slug}}" class="{{key == 0 ? 'active' : ''}}">
                    {{item.category_title}}
                   </a>
                </li>
           </ul>
           <div class="tab-content">
               <div *ngFor = "let catItem of menuList let key = index" id="{{catItem.category_slug}}" class="tab-pane {{key == 0 ? 'active' : ''}}">
                   <div class="menu-box">
                       <ul class="menu-list">
                           <li *ngFor = "let item of catItem.items">
                               <div class="item-left">
                                   <img src="assets/images/cup/{{item.icon}}" alt=""/>
                                   <h4 class="title"> {{item.title}}  <i *ngIf="item.veg" class='fa fa-leaf' style='font-size:22px;color:green'></i></h4>
                                   
                                   <p class="description">{{item.short_description}}</p>
                               </div>
                               <div class="item-right">
                                   <h5 class="price">€{{item.price}}</h5>
                               </div>
                           </li>
                       </ul>
                       <div class="text-center">
                           <a [routerLink] = "'/our-menu'" class="btn bg-secondry">VOIR NOTRE CARTE</a>
                       </div>
                   </div>
               </div>
           </div>
       </div>
   </div>
</div>
<!-- Menu End -->
