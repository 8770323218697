import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  aboutInfo : any = 
    {
      introtext: 'Salut!',
      title: 'À PROPOS DE NOUS',
      subtitle: 'RESTAURANT INDIEN',
      description: 'Spécialisé dans la gastronomie indienne, Tami est un restaurant authentique de la culture culinaire indienne. Avec nos curry maison, nos savoureux entrées, les assortiments de pains et desserts authentiques indiens, nous vous invitons à venir profiter du vrai goût d’Inde à Bordeaux. En utilisant les meilleurs ingrédients, nous priorisons sur la création de plats indiens sains et de haute qualité avec une faible teneur en huile afin de satisfaire le palet français.',
    }
  
  
  constructor() { }

  ngOnInit(): void {
  }

}
