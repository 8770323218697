<!-- Reservation Form -->
<div class="section-full content-inner bg-pink-light">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 m-b30  align-self-center ">
                <div class="contact-form">
                    <div class="section-head full-head text-center">
                        <h2 class="main-title text-primary">Besoin d'une réservation ?</h2>
                        <h3 class="title">RÉSERVER UNE TABLE </h3>
                        <div class="dlab-separator"></div>
                    </div>
                    <div class="dzFormMsg"></div>
                    <form method="post" class="dzForm res-form" action="script/contact.php">
                        <input type="hidden" value="Contact" name="dzToDo" >
                        <div class="row sp5">
                         <!--    <div class="col-md-12">
                                <div class="form-group">
                                    <div class="input-group">
                                        <input name="dzName" type="text" required class="form-control" placeholder="votre nom"   [(ngModel)]="ReservationModel.dzName">
                                    </div>
                                </div>
                            </div> -->

                          <!--   <div class="col-md-12">
                                <div class="form-group">
                                    <div class="input-group"> 
                                        <input name="dzEmail" type="email" class="form-control" required  placeholder="votre e-mail"  [(ngModel)]="ReservationModel.dzEmail" >
                                    </div>
                                </div>
                            </div> -->

                <!--             <div class="col-md-12">
                                <div class="form-group">
                                    <div class="input-group"> 
                                        <input name="dzTpNum" type="TpNum" class="form-control" required  placeholder="Votre numéro de contact"  [(ngModel)]="ReservationModel.dzTpNum" >
                                    </div>
                                </div>
                            </div> -->


                  <!--           <div class="col-md-6 col-md-6 col-sm-6">
                                <div class="form-group" >
                                    <select class="selectpicker" [(ngModel)]="ReservationModel.dzPerson" [ngModelOptions]="{standalone: true}" name = "dzPer">
                                        <option value="1">1 Couvert</option>
                                        <option value="2" >2 Couverts</option>
                                        <option value="3">3 Couverts</option>
                                        <option value="4">4 Couverts</option>
                                        <option value="5">5 Couverts</option>
                                        <option value="6">6 Couverts</option>
                                        <option value="7">7 Couverts</option>
                                        <option value="8">8 Couverts</option>
                                        <option value="9">9 Couverts</option>
                                        <option value="10">10 Couverts</option>
                                    </select>
                                </div>
                            </div> -->

 <!--                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" class="form-control" required placeholder="yyyy-mm-dd" [(ngModel)]="dzDate" ngbDatepicker 
                                    #d2="ngbDatepicker" >
                                    <div class="input-group-append">
                                        <div class="input-group-text" (click)="d2.toggle()">
                                            <i class="fa fa-calendar" style="cursor: pointer;"></i>
                                        </div>
                                    </div>

                                </div>
                            </div> -->

  


         <!--                    <div class="col-md-12">
                                <div class="form-group">
                                    <div class="input-group"> 
                                        <input name="dzTime" type="text" class="form-control" required  placeholder="arriver" [(ngModel)]="ReservationModel.dzTime">
                                    </div>
                                </div>
                            </div> -->

                            <!-- <div class="col-md-6 col-md-6 col-sm-6">
                                <div class="form-group">
                                    <select class="selectpicker">
                                        <option value="1">First Choice</option>
                                        <option value="2" >Second Choice</option>
                                        <option value="3">Third Choice</option>
                                    </select>
                                </div>
                            </div> -->

<!--                             <div class="col-md-12">
                                <div class="form-group">
                                    <div class="input-group">
                                        <textarea name="dzMessage" rows="4" class="form-control" required placeholder="Votre message..." [(ngModel)]="ReservationModel.dzMessage"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <button name="submit" type="button"  class="btn btn-block bg-secondry" (click) = "SendEmail()" > <span>Soumettre</span> </button>
                            </div> -->
                            <!-- <div class="dlab-separator"></div> -->

                            <div class="col-lg-12 col-md-12">
                                <div class="contact-box">
                                    <p class="opening-hours">
                                        <a href="tel:+33 5 57 65 12 36">Tel: +33 5 57 65 12 36   </a>
                                    </p>
                                </div>
                            </div>

                   <!--          <div class="col-lg-12 col-md-12">
                                <p class="opening-hours" >Écrivez-nous: tamisarl@hotmail.com </p>
                            </div> -->
                            

                            <div class="col-lg-12 col-md-12">
                                <div class="contact-box">
                                    <p class="opening-hours"></p>
                                    <p class="opening-hours">Mardi au samedi <span>11:30 - 14:00 , 18:00 - 22:00</span></p>
                                    <!-- <p class="opening-hours">                <span>18:00 - 22:00</span></p> -->
                                    <p class="opening-hours">Dimanche <span>18:00 - 22:00</span></p>
                                </div>
                            </div>

               <!--              <div class="col-lg-6 col-md-6">
                                <div class="contact-box">
                                    <p class="opening-hours"></p>
                                    <p class="opening-hours">Dimanche <span>18:00 - 22:00</span></p>
                                </div>
                            </div> -->



                        </div>
                    </form>
                </div>
            </div>	
            <div class="col-lg-6 m-b30">
                <div class="row sp15 h-100">
                    <div class="col-lg-6 col-md-6 col-6">
                        <img class="radius-sm img-cover" src="assets/images/about/pic2.jpg" alt="">
                    </div>
                    <div class="col-lg-6 col-md-6 col-6">
                        <img class="radius-sm img-cover" src="assets/images/about/pic3.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Reservation Form END -->
