<!-- Footer -->
<footer class="site-footer">
    <!-- footer top part -->
    <div class="footer-top">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="text-left text-white">
                        <p class="copyright">Copyright © 2020 Turbo Creative</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="text-right">
                        <ul  class="list-inline footer-social m-b0">
                            <li *ngFor = "let item of socialicon"><a [href] ="item.url" target="_blank" [ngClass] = "item.icon"></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button class="scroltop" type="button"><i class="fa fa-angle-up"></i></button>
</footer>
<!-- Footer END -->
