<!-- About Us -->
<div class="section-full content-inner-2 bg-pink-light">
    <div class="container">
        <div class="section-head text-center m-b0">
            <h2 class="main-title text-primary">Welcome</h2>
            <h3 class="title">À PROPOS DE NOUS</h3>
            <h4 class="sub-title">INDIAN RESTAURANT</h4>
            <div class="dlab-separator"></div>
            <p class="m-b0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.</p>
        </div>
    </div>
</div>
<!-- About Us END -->
