import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  pageInfo : any = {
    pageTitle:'À propos de nous',
    pageSubTitle:'Tami indien restaurant'
  }

  


  //pageTitle = 'ABOUT US';
  //pageSubTitle = 'CafeZone cafe & restaurant'

  constructor() { }

  ngOnInit(): void {
    
  }



}
