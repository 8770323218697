<!-- Slider -->
<owl-carousel-o [options]="customOptions" class="main-slider  owl-btn-center-lr">
    <ng-template carouselSlide *ngFor = "let slide of slideStore">
        <div class="slide" style = "background-image: url('{{slide.image}}')">
            <div class="content">
                <span class="text-primary">{{slide.introtext}}</span>
                <h2 class="title">{{slide.title}}</h2>
                <h4 class="sub-title">{{slide.subtitle}}</h4>
                <a [routerLink] = "'/about'" href="about.html" class="btn">À PROPOS DE NOUS</a>
                <a [routerLink] = "'/reservation'" href="reservation.html" class="btn-secondry">RÉSERVER UNE TABLE!</a>
            </div>
        </div>
    </ng-template>
 </owl-carousel-o> 
<!-- Slider END --> 
