<!-- Our Info -->
<div class="section-full bg-pink-light content-inner-2">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="section-head full-head text-center">
                    <h2 class="main-title text-primary">Authentique</h2>
                    <h3 class="title">DE BON GOÛT</h3>
                    <h4 class="sub-title">RESTAURANT INDIEN</h4>
                    <div class="dlab-separator"></div>
                    <p>Saveur indienne que tout le monde aime! Toute notre cuisine est faite maison, avec des produits frais qui arrivent tous les jours. Et nous assurons la qualité de nos produits</p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row sp20 lightgallery">
                    <div *ngFor = "let item of gallery" class="col-lg-6 col-md-6 col-6">
                        <div class="dlab-gallery-box">
                            <div class="dlab-thum">
                                <span data-exthumbimage="assets/images/gallery/pic1.jpg" data-src="images/gallery/orignal/pic1.jpg" class="check-km" title="Light Gallery Grid 1">	
                                    <img src="assets/images/gallery/{{item.image}}" alt="">
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Our Info End -->
