import { Component, OnInit } from '@angular/core';
declare var CafeZone: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
	  CafeZone.init();
  }

}
