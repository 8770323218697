<!-- Contact Info -->
<div class="section-full content-inner-2">
    <div class="container">
       <div class="row sp0 contact-box align-items-center">
           <div class="col-lg-6 col-md-6">
               <div class="section-head full-head text-center">
                   <h2 class="main-title text-primary">Notre emplacement</h2>
                   <h3 class="title">Rendez nous visite!</h3>
                   <div class="dlab-separator"></div>
                   <p class="m-b0">Nous sommes situés à : 11B Av. Jean Jaurès, 33600 Pessac, France</p>
               </div>
           </div>
           <div class="col-lg-6 col-md-6">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d465.55246445962786!2d-0.6304275570993444!3d44.806367737568515!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd54d94cd28d5533%3A0xc701c8e48ba00684!2sTami!5e0!3m2!1sen!2slk!4v1640796951254!5m2!1sen!2slk" style="border:0; height: 300px; width: 100%; margin-bottom: -5px;" allowfullscreen=""></iframe> 
               <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14443.076073250879!2d75.8417313!3d25.177276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1577358063715!5m2!1sen!2sin" style="border:0; height: 300px; width: 100%; margin-bottom: -5px;" allowfullscreen=""></iframe> -->
           </div>
       </div>
       <div class="row sp0 contact-box contact-box-reversed">
           <div class="col-lg-6 col-md-6">
               <img src="assets/images/our-services/pic1.jpg" class="img-cover" alt="">
           </div>
           <div class="col-lg-6 col-md-6 align-self-center">
               <div class="section-head full-head text-center">
                   <h2 class="main-title text-primary">Avoir une question?</h2>
                   <h3 class="title">Nous Contacter</h3>
                   <div class="dlab-separator"></div>
                   <!-- <p class="m-b0">Call us: +33 5 57 65 12 36</p> -->
                   <p class="m-b0">
                    <a href="tel:+33 5 57 65 12 36">Tel: +33 5 57 65 12 36</a>
                  </p>
                   <p class="m-b0">Écrivez-nous: tamisarl@hotmail.com</p>
               </div>
           </div>
       </div>
   </div>
</div>
<!-- Contact Info End -->
