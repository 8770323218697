import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-service-section',
  templateUrl: './about-service-section.component.html',
  styleUrls: ['./about-service-section.component.css']
})
export class AboutServiceSectionComponent implements OnInit {
  service = [
    {
      title: "CÉLÈBRE POUR NOTRE SAVEUR INDIENNE",
      image: "icon6.png"
    },
    {
      title: "RÉSERVATIONS TÉLÉPHONIQUES",
      image: "icon2.png"
    },
    {
      title: "OUVERT TOUS LES JOURS ",
      desc1: "Mardi-jeudi 12.00-14.00, 19.00 - 22.00 ",
      desc2 : "Vendredi, Samedi 12.00 - 14.00, 18.00 - 23.00  ",
      desc3 : "Dimanche 18.00 - 22.00",
      desc4 : "Lundi fermé",
      image: "icon3.png"
    },
    {
      title: "SITUÉ À PESSAC",
      image: "icon4.png"
    }
    
  ]
  











  constructor() { }

  ngOnInit(): void {
  }

}
