<!-- Menu -->
<div class="section-full bg-pink-light">
    <div class="container">
       <div class="dlab-tabs menu-tabs style1">

        
           <ul class="nav nav-tabs">
               <li *ngFor = "let item of menuList let key = index" >
                    <a data-toggle="tab" href="#{{item.category_slug}}" class="{{key == 0 ? 'active' : ''}}"> 
                    <div class="icon-bx">
                        <img src="assets/images/icons/{{item.category_icon}}" alt="">
                    </div>
                    <h4>{{item.category_title}}</h4>
                    <h6>{{item.category_tagname}}</h6>
                </a>
               </li>
           </ul>
           <div class="tab-content">
               
                <div *ngFor = "let catItem of menuList let key = index" id="{{catItem.category_slug}}" class="tab-pane {{key == 0 ? 'active' : ''}}">
                   <div class="menu-box">
                       <ul class="menu-list">
                           <li *ngFor="let item of catItem.items ">
                               <div class="item-left">
                                   <img src="assets/images/cup/{{item.icon}}" alt=""/>
                                   <h4 class="title">{{item.title}}  <i *ngIf="item.veg" class='fa fa-leaf' style='font-size:22px;color:green'> </i></h4>
                                   <p class="description">{{item.short_description}}</p>
                               </div>
                               <div class="item-right">
                                   <h5 class="price">€{{item.price}}</h5>
                               </div>
                           </li>
                           
                        </ul>
                        
               <!--          <div class="menu-box">
                            <div class="text-center">
                           <a [routerLink] = "'/our-menu'" class="btn bg-secondry">VIEW OUR COMPLETE MENU</a>
                            </div>
                            <ul class="menu-list">
                            <li *ngFor="let item of catItem.Sub_items ">
                                <div class="item-left">
                                    <img src="assets/images/cup/{{item.icon}}" alt=""/>
                                    <h4 class="title">{{item.title}}</h4>
                                    <p class="description">{{item.short_description}}</p>
                                </div>
                                <div class="item-right">
                                    <h5 class="price">€{{item.price}}</h5>
                                </div>
                            </li>
                            </ul>
                        </div> -->
                        
                       <!-- <div class="text-center">
                           <a [routerLink] = "'/our-menu'" class="btn bg-secondry">VIEW OUR COMPLETE MENU</a>
                       </div> -->
                   </div>
               </div>



           </div>
       </div>
   </div>
</div>
<!-- Menu End -->