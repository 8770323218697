import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-menu',
  templateUrl: './our-menu.component.html',
  styleUrls: ['./our-menu.component.css']
})
export class OurMenuComponent implements OnInit {
  
  pageInfo : any = {
    pageTitle:'NOTRE CARTE',
    pageSubTitle:'15 % de remise à EMPORTER'
  }
  constructor() { }

  ngOnInit(): void {
  }

}
