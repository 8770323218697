import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-section',
  templateUrl: './menu-section.component.html',
  styleUrls: ['./menu-section.component.css']
})
export class MenuSectionComponent implements OnInit {

  menuList = [
    {
      category_icon: "",
      category_title: "Entrée",
      category_tagname: "",
      category_slug : "coffee",
      items: [
        {
          icon: "MixGril.png",
          title: "Mix grill ( Pour deux personnes )",
          short_description: "Côtelette d'agneau grillée , poulet tikka , palak pakora , seekh kebab , crevette panée , salade",
          price: "16.00",
        },
        {
          icon: "Palakpakora.png",
          title: "Palak Pakora",
          short_description: "Délicieux beignets d'épinards croustillants à base de feuilles d'épinards frais, d'oignons, de farine de pois chiches, de graines de carambole",
          price: "6.50",
          veg:"veg"
        },
        {
          icon: "Bainganpakora.png",
          title: "Baingan Pakora.",
          short_description: "Délicieux aubergines croustillantes à base de farine de pois chiches , de graines de carambole",
          price: "6.50",
          veg:"veg"
        },
        {
          icon: "Tandooricôtelettedagneau.png",
          title: "Tandoori côtelette d'agneau",
          short_description: "Mariné au yaourt, coriandre, menthe et diverses épices indiennes cuit au tandoor , servi avec salade",
          price: "9.00",
        },
        {
          icon: "SeekhKebab.png",
          title: "Seekh Kebab",
          short_description: "Boeuf haché mariné , cuit en brochette au tandoor",
          price: "7.50",
        },
        {
          icon: "PouletTikka.png",
          title: "Poulet Tikka",
          short_description: "Morceaux de poulet désossés marinés à la sauce tikka et cuit au tandoor. servi avec salade",
          price: "6.50",
        },
       /*  {
          icon: "CrevettePannée.png",
          title: "Crevette Pannée",
          short_description: ".",
          price: "7.50",
        }, */
        {
          icon: "Gingatandoori.png",
          title: "Jhinga Tandoori",
          short_description: "Gambas marinées au gingembre, ail, poivre et diversses épices, grillées au four tandoor, servi avec de la salade.",
          price: "13.00",
        },

      ]

    },

    {
      category_icon: "",
      category_title: "Plats ( Servi avec Riz )",
      category_tagname: "",
      category_slug : "lunch",
      items: [
        {
          icon: "PouletTikkamasala.png",
          title: "Poulet Tikka masala",
          short_description: "Morceaux de poulet marinés au tandoor cuits dans une sauce crémeuse à base de tomates avec du garam masala",
          price: "14.50",
        
        },
        {
          icon: "PouletRaantandoori.png",
          title: "Poulet Raan tandoori",
          short_description: "Morceaux de poulet , sauce tomate à la coriandre fraîche et à la menthe",
          price: "13.50",
        },
        {
          icon: "Pouletkarhai.png",
          title: "Poulet karhai",
          short_description: "Morceaux de poulet sauté avec piments, poivron, oignon, tomate et gingembre avec sauce épicée",
          price: "13.50",
        },
        {
          icon: "Pouletaubeurre.png",
          title: "Poulet au beurre",
          short_description: "Morceaux de poulet avec une sauce riche et crémeuse de noix de cajou, d'amande, de beurre et d'épices avec des notes d'ail",
          price: "14.50",
        },
        {
          icon: "LambChopmasala.png",
          title: "Lamb Chop masala",
          short_description: "Morceaux de côtelettes d'agneau grillés cuits dans une sauce masala moyennement épicée avec des herbes et des épices indiennes",
          price: "16.50",
        },
        {
          icon: "RoyalLambchop.png",
          title: "Royal Lamb chop",
          short_description: "Morceaux d’agneau préparé en sauce crémeuse, yaourt, oignon, gingembre, cannelle, coriandre",
          price: "16.50",
        },
        {
          icon: "Keemamatar.png",
          title: "Keema matar",
          short_description: "Viande hachée avec une sauce curry demi-sec à base de bœuf haché, petits pois et épices indiennes",
          price: "14.00",
        },
        {
          icon: "Crevetteskorma.png",
          title: "Crevettes korma",
          short_description: "Crevettes décortiquées cuites dans une sauce douce et aromatique à base de noix de cajou, lait de coco, ail, herbes",
          price: "16.00",
        },
        {
          icon: "Crevettesmasala.png",
          title: "Crevettes masala",
          short_description: "Crevettes décortiquées cuites dans des oignons moyennement épicés, sauce tomate et poivrons",
          price: "16.00",
        },
        {
          icon: "GambasLabaabdaar.png",
          title: "Gambas Labaabdaar",
          short_description: "Gambas grillées servies avec une sauce douce aromatique",
          price: "19.50",
        },
        {
          icon: "Mixvegmughlai.png",
          title: "Mix veg mughlai",
          short_description: "Mélanger les légumes cuits dans une sauce crémeuse douce et parfumée.",
          price: "13.00",
          veg:"veg"
        },
        {
          icon: "DaalMakhani.png",
          title: "Daal Makhani",
          short_description: "Lentilles noires entières cuites lentement préparées dans une sauce tomate crémeuse au beurre avec des feuilles de fenugrec",
          price: "12.50",
          veg:"veg"
        },
      ]

    },

    {
      category_icon: "",
      category_title: "Dessert",
      category_tagname: "            ",
      category_slug : "specials",
      items: [
        {
          icon: "FaloodaPistache.png",
          title: "Falooda Pistache",
          short_description: "        .",
          price: "5.50",
        },
        {
          icon: "Cheesecake.png",
          title: "Cheesecake",
          short_description: "             ",
          price: "4.00",
        },
        {
          icon: "Kulfi.png",
          title: "Kulfi",
          short_description: "Glace indienne faite maison, parfumée à la pistache, à la cardamome, aux extraits secs de lait et aux noix",
          price: "5.50",
        },
        {
          icon: "Glace2boulesauchoix.png",
          title: "Glace 2 boules au choix",
          short_description: "Fraise , Pistache , Vanille , Chocolat",
          price: "4.50",
        },
        {
          icon: "FondantChocolat.png",
          title: "Fondant Chocolat ( servi avec glace vanille et crème anglaise )",
          short_description: "                 ",
          price: "4.50",
        }

      ]

    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
