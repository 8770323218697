<!-- Contact Form -->
<div class="section-full content-inner bg-pink-light">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12 m-b30 align-self-center">
                <div class="contact-form">
                    <div class="section-head full-head text-center">
                        <h2 class="main-title text-primary">Avoir une question?</h2>
                        <h3 class="title">Écrivez-nous</h3>
                        <div class="dlab-separator"></div>
                    </div>
                    <div class="dzFormMsg"></div>
                    <form method="post" class="dzForm" action="script/contact.php">
                        <input type="hidden" value="Contact" name="dzToDo" >
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <div class="input-group">
                                        <input name="dzName" type="text" required class="form-control" placeholder="Votre nom">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <div class="input-group"> 
                                        <input name="dzEmail" type="email" class="form-control" required  placeholder="Votre Email" >
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <div class="input-group">
                                        <input name="dzOther[Phone]" type="text" required class="form-control" placeholder="Téléphoner">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <div class="input-group">
                                        <textarea name="dzMessage" rows="4" class="form-control" required placeholder="Votre Message..."></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <button name="submit" type="submit" value="Submit" class="btn btn-block bg-secondry"> <span>Soumettre</span> </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>	
            <div class="col-lg-6 col-md-12 m-b30">
                <div class="contact-reserve text-white text-uppercase">
                    <img class="img-cover" src="assets/images/about/pic12.jpeg" alt="">
                    <div class="contact-text">
                        <h2 class="title">Besoin d'une table ?</h2>
                        <!-- <h6 class="sub-title">Faites votre réservation en ligne !</h6> -->
                        <a [routerLink] = "'/contact'" class="btn">Réservez maintenant!</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Form END -->
