import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-menu-two',
  templateUrl: './our-menu-two.component.html',
  styleUrls: ['./our-menu-two.component.css']
})
export class OurMenuTwoComponent implements OnInit {

  menuList = [
    {
      image : "Biryani.png",
      title: "Veg diwani Biryani",
      description: "Mélange de légumes mijotés avec riz basmati, oignons frits, fruits secs, herbes et épices diverses servi avec sauce biryani et chutney de menthe",
      price: "13.00",
        veg:"veg"
    },
    {
      image : "Biryani.png",
      title: "Biryani : Chicken ou Agneau ou Crevettes décortiquées",
      description: "Mijoté avec riz basmati, oignons frits, fruits secs, herbes et épices diverses servi avec sauce biryani et chutney à la menthe",
      price: "15.50",
    },
    {
      image : "ThaliMix.png",
      title: "THALI ( mix )",
      description: "Lamb chop masala , poulet tikka masala , daal makhani ,poulet beurre , palak pakora , seekh kebab , servi avec riz et naan au beurre",
      price: "22.00",
    },
    {
      image : "ThaliMix.png",
      title: "THALI VEG ( mix )",
      description: "Mix veg mughlai , daal makhani , palak pakora , baingan pakora , salade , servi avec riz et naan au beurre",
      price: "16.00",
      veg:"veg"
    },
    {
      image : "Crousty.png",
      title: "Crousty",
      description: ".",
      price: "10.00",
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
