import { Component, OnInit } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';
import { LightboxConfig, Lightbox } from 'ngx-lightbox';
declare  var _albums:  any;

@Component({
  selector: 'app-gallery-section',
  templateUrl: './gallery-section.component.html',
  styleUrls: ['./gallery-section.component.css']
})
export class GallerySectionComponent implements OnInit {

   _albums = [];
   
  galleryCol1 = [
    {
      src: "assets/images/gallery/orignal/pic1.jpg",
      caption:"Image Caption 1",
      thumb: "assets/images/gallery/pic1.jpg",
    },

    {
      src: "assets/images/gallery/orignal/pic11.jpeg",
      caption:"Image Caption 1",
      thumb: "assets/images/gallery/pic11.jpeg",
    },

    {
      src: "assets/images/gallery/orignal/pic10.jpg",
      caption:"Image Caption 1",
      thumb: "assets/images/gallery/pic10.jpg",
    },


    {
      src: "assets/images/gallery/orignal/pic12.jpeg",
      caption:"Image Caption 1",
      thumb: "assets/images/gallery/pic12.jpeg",
    },

    {
      src: "assets/images/gallery/orignal/pic13.jpeg",
      caption:"Image Caption 1",
      thumb: "assets/images/gallery/pic13.jpeg",
    },


    {
      src: "assets/images/gallery/orignal/pic15.jpeg",
      caption:"Image Caption 2",
      thumb: "assets/images/gallery/pic15.jpeg",
    },

    {
      src: "assets/images/gallery/orignal/pic2.jpg",
      caption:"Image Caption 2",
      thumb: "assets/images/gallery/pic2.jpg",
    },

    {
      src: "assets/images/gallery/orignal/pic16.jpeg",
      caption:"Image Caption 2",
      thumb: "assets/images/gallery/pic16.jpeg",
    },

    {
      src: "assets/images/gallery/orignal/pic18.jpeg",
      caption:"Image Caption 3",
      thumb: "assets/images/gallery/pic18.jpeg",
    },

    {
      src: "assets/images/gallery/orignal/pic4.jpg",
      caption:"Image Caption 3",
      thumb: "assets/images/gallery/pic4.jpg",
    },

    {
      src: "assets/images/gallery/orignal/pic19.jpeg",
      caption:"Image Caption 3",
      thumb: "assets/images/gallery/pic19.jpeg",
    },

/*     {
      src: "assets/images/gallery/orignal/pic20.jpeg",
      caption:"Image Caption 4",
      thumb: "assets/images/gallery/pic20.jpeg",
    }, */
    {
      src: "assets/images/gallery/orignal/pic5.jpg",
      caption:"Image Caption 5",
      thumb: "assets/images/gallery/pic5.jpg",
    },

    {
      src: "assets/images/gallery/orignal/pic21.jpeg",
      caption:"Image Caption 5",
      thumb: "assets/images/gallery/pic21.jpeg",
    }

  ]

  galleryCol2 = [
    {
      src: "assets/images/gallery/orignal/pic6.jpg",
      caption:"Image Caption 1",
      thumb: "assets/images/gallery/pic6.jpg",
    },

    {
      src: "assets/images/gallery/orignal/pic17.jpeg",
      caption:"Image Caption 1",
      thumb: "assets/images/gallery/pic17.jpeg",
    },

    {
      src: "assets/images/gallery/orignal/pic22.jpeg",
      caption:"Image Caption 2",
      thumb: "assets/images/gallery/pic22.jpeg",
    },
    {
      src: "assets/images/gallery/orignal/pic20.jpeg",
      caption:"Image Caption 3",
      thumb: "assets/images/gallery/pic20.jpeg",
    },
    {
      src: "assets/images/gallery/orignal/pic23.jpeg",
      caption:"Image Caption 4",
      thumb: "assets/images/gallery/pic23.jpeg",
    },
    {
      src: "assets/images/gallery/orignal/pic13.jpeg",
      caption:"Image Caption 1",
      thumb: "assets/images/gallery/pic13.jpeg",
    },
    {
      src: "assets/images/gallery/orignal/pic24.jpeg",
      caption:"Image Caption 1",
      thumb: "assets/images/gallery/pic24.jpeg",
    },
    {
      src: "assets/images/gallery/orignal/pic27.jpeg",
      caption:"Image Caption 1",
      thumb: "assets/images/gallery/pic27.jpeg",
    }
    ,
    {
      src: "assets/images/gallery/orignal/pic26.jpeg",
      caption:"Image Caption 1",
      thumb: "assets/images/gallery/pic26.jpeg",
    }
  

  ]
  constructor(private _lightboxConfig: LightboxConfig, private _lightbox: Lightbox) {}
  
  open(_albumsArray:any, index: number): void {
    // open lightbox
    // this._lightbox.open(this._albums, index);
    this._lightbox.open(_albumsArray, index);
  }
 
  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

    ngOnInit(): void {
    }

}
