import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {
  public scrollbarOptions = { axis: 'y', theme: 'light-thin' };
  navList =  [
      {"title": "ACCUEIL", "url" : "/"} ,
      {"title": "À propos de nous", "url" : "/about"} ,
      {"title": "NOTRE CARTE", "url" : "/our-menu"},
      {"title": "Galerie", "url" : "/gallery"} ,
      // {"title": "Parties & Events", "url" : "/events"} ,
      // {"title": "Blog", "url" : "/blog"},
      {"title": "Nous Contacter", "url" : "/contact"} ,
      {"title": "Réservation", "url" : "/reservation"} 
  ];

  socialicon = [
    {icon: 'fa fa-facebook' , url:"https://www.facebook.com/profile.php?id=100075627810198"},
    {icon: 'fa fa-instagram', url:"https://www.instagram.com/tamiindianflavor/"},
    // {icon: 'fa fa-twitter'},
    // {icon: 'fa fa-linkedin'},
    // {icon: 'fa fa-google-plus'}
];

  constructor(
    
  ) { }

  ngOnInit(): void {
  }

}
