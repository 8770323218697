import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-menu-one',
  templateUrl: './our-menu-one.component.html',
  styleUrls: ['./our-menu-one.component.css']
})
export class OurMenuOneComponent implements OnInit {

  menuList = [
    {
      category_icon: "icon5.png",
      category_title: "Entrée",
      category_tagname: " .",
      category_slug : "Entrée",
      items: [
        {
          icon: "MixGril.png",
          title: "Mix grill ( Pour deux personnes )",
          short_description: "Côtelette d'agneau grillée , poulet tikka , palak pakora , seekh kebab , crevette panée , salade",
          price: "16.00",
        },
        {
          icon: "Palakpakora.png",
          title: "Palak Pakora",
          short_description: "Délicieux beignets d'épinards croustillants à base de feuilles d'épinards frais, d'oignons, de farine de pois chiches, de graines de carambole",
          price: "6.50",
          veg:"veg"
          
        },
        {
          icon: "Bainganpakora.png",
          title: "Baingan Pakora.",
          short_description: "Délicieux aubergines croustillantes à base de farine de pois chiches , de graines de carambole",
          price: "6.50",
          veg:"veg"
        },
        {
          icon: "Tandooricôtelettedagneau.png",
          title: "Tandoori côtelette d'agneau",
          short_description: "Mariné au yaourt, coriandre, menthe et diverses épices indiennes cuit au tandoor , servi avec salade",
          price: "9.00",
        },
        {
          icon: "SeekhKebab.png",
          title: "Seekh Kebab",
          short_description: "Boeuf haché mariné , cuit en brochette au tandoor",
          price: "7.50",
        },
        {
          icon: "PouletTikka.png",
          title: "Poulet Tikka",
          short_description: "Morceaux de poulet désossés marinés à la sauce tikka et cuit au tandoor. servi avec salade",
          price: "6.50",
        },
        /* {
          icon: "CrevettePannée.png",
          title: "Crevette Pannée",
          short_description: ".",
          price: "7.50",
        }, */
        
        {
          icon: "Gingatandoori.png",
          title: "Jhinga Tandoori",
          short_description: "Gambas marinées au gingembre, ail, poivre et diversses épices, grillées au four tandoor, servi avec de la salade.",
          price: "13.00",
        },

      ],
/*       Sub_items: [
        {
          icon: "cup1.png",
          title: "111111_Mix grill ( Pour deux personnes )",
          short_description: "Côtelette d'agneau grillée , poulet tikka , palak pakora , seekh kebab , crevette panée , salade",
          price: "16.00",
        },
      ] */

    },
    {
      category_icon: "icon5.png",
      category_title: "Pain & Naan",
      category_tagname: "Pain indien/Naan",
      category_slug : "Pain",
      items: [
        {
          icon: "fromage.png",
          title: "Naan fromage",
          short_description: ".",
          price: "3.00",
        },
        {
          icon: "fromage.png",
          title: "Naan à l'ail",
          short_description: ".",
          price: "2.50",
        },
        {
          icon: "fromage.png",
          title: "Naan au Beurre",
          short_description: ".",
          price: "2.50",
        },
        {
          icon: "fromage.png",
          title: "Naan Nature",
          short_description: ".",
          price: "2.00",
        },
      
      ]

    },
    {
      category_icon: "icon6.png",
      category_title: "Plats",
      category_tagname: "Servi avec riz",
      category_slug : "Plats",
      items: [
        {
          icon: "PouletTikkamasala.png",
          title: "Poulet Tikka masala",
          short_description: "Morceaux de poulet marinés au tandoor cuits dans une sauce crémeuse à base de tomates avec du garam masala",
          price: "14.50",
        },
        {
          icon: "PouletRaantandoori.png",
          title: "Poulet Raan tandoori",
          short_description: "Morceaux de poulet , sauce tomate à la coriandre fraîche et à la menthe",
          price: "13.50",
        },
        {
          icon: "Pouletkarhai.png",
          title: "Poulet karhai",
          short_description: "Morceaux de poulet sauté avec piments, poivron, oignon, tomate et gingembre avec sauce épicée",
          price: "13.50",
        },
        {
          icon: "Pouletaubeurre.png",
          title: "Poulet au beurre",
          short_description: "Morceaux de poulet avec une sauce riche et crémeuse de noix de cajou, d'amande, de beurre et d'épices avec des notes d'ail",
          price: "14.50",
        },
        {
          icon: "LambChopmasala.png",
          title: "Lamb Chop masala",
          short_description: "Morceaux de côtelettes d'agneau grillés cuits dans une sauce masala moyennement épicée avec des herbes et des épices indiennes",
          price: "16.50",
        },
        {
          icon: "RoyalLambchop.png",
          title: "Royal Lamb chop",
          short_description: "Morceaux d’agneau préparé en sauce crémeuse, yaourt, oignon, gingembre, cannelle, coriandre",
          price: "16.50",
        },
        {
          icon: "Keemamatar.png",
          title: "Keema matar",
          short_description: "Viande hachée avec une sauce curry demi-sec à base de bœuf haché, petits pois et épices indiennes",
          price: "14.00",
        },
        {
          icon: "Crevetteskorma.png",
          title: "Crevettes korma",
          short_description: "Crevettes décortiquées cuites dans une sauce douce et aromatique à base de noix de cajou, lait de coco, ail, herbes",
          price: "16.00",
        },
        {
          icon: "Crevettesmasala.png",
          title: "Crevettes masala",
          short_description: "Crevettes décortiquées cuites dans des oignons moyennement épicés, sauce tomate et poivrons",
          price: "16.00",
        },
        {
          icon: "GambasLabaabdaar.png",
          title: "Gambas Labaabdaar",
          short_description: "Gambas grillées servies avec une sauce douce aromatique",
          price: "19.50",
        },
        {
          icon: "Mixvegmughlai.png",
          title: "Mix veg mughlai",
          short_description: "Mélanger les légumes cuits dans une sauce crémeuse douce et parfumée.",
          price: "13.00",
          veg:"veg"
        },
        {
          icon: "DaalMakhani.png",
          title: "Daal Makhani",
          short_description: "Lentilles noires entières cuites lentement préparées dans une sauce tomate crémeuse au beurre avec des feuilles de fenugrec",
          price: "12.50",
          veg:"veg"
        },
        {
          icon : "Biryani.png",
          title: "Veg diwani Biryani",
          short_description: "Mélange de légumes mijotés avec riz basmati, oignons frits, fruits secs, herbes et épices diverses servi avec sauce biryani et chutney de menthe",
          price: "13.00",
          veg:"veg"
        },
        {
          icon : "Biryani.png",
          title: "Biryani : Chicken ou Agneau ou Crevettes décortiquées",
          short_description: "Mijoté avec riz basmati, oignons frits, fruits secs, herbes et épices diverses servi avec sauce biryani et chutney à la menthe",
          price: "15.50",
        },
        {
          icon : "ThaliMix.png",
          title: "THALI ( mix )",
          short_description: "Lamb chop masala , poulet tikka masala , daal makhani ,poulet beurre , palak pakora , seekh kebab , servi avec riz et naan au beurre",
          price: "22.00",
        },
        {
          icon : "ThaliMix.png",
          title: "THALI VEG ( mix )",
          short_description: "Mix veg mughlai , daal makhani , palak pakora , baingan pakora , salade , servi avec riz et naan au beurre",
          price: "16.00",
          veg:"veg"
        },
        {
          icon : "Crousty.png",
          title: "Crousty",
          short_description: " . ",
          price: "10.00",
        }
      ]

    },

    {
      category_icon: "icon8.png",
      category_title: "BOISSONS",
      category_tagname: "FRAIS & CHAUD",
      category_slug : "diner",
      items: [
        {
          icon: "LassiMangue.png",
          title: "Lassi Mangue",
          short_description: " ..",
          price: "4.50",
        },
        {
          icon: "LassiRose.png",
          title: "Lassi Rose",
          short_description: ".",
          price: "4.50",
        },
        {
          icon: "VirjinMojito.png",
          title: "Virjin Mojito",
          short_description: "Original , Fraise , Passion",
          price: "5.00",
        },
        {
          icon: "VirjinCooktail.png",
          title: "Virjin Cooktail",
          short_description: "Maison",
          price: "5.00",
        },
        {
          icon: "Coca.png",
          title: "Coca",
          short_description: "33 Cl",
          price: "3.00",
        },
        {
          icon: "cocaZero33cl.png",
          title: "Coca Zéro",
          short_description: "33 Cl",
          price: "3.00",
        },
        {
          icon: "CocaCherry.png",
          title: "Coca Cherry",
          short_description: "33 Cl",
          price: "3.00",
        },
        {
          icon: "LorinaLimonade.png",
          title: "Lorina Limonade",
          short_description: "33 Cl",
          price: "3.00",
        },
        {
          icon: "FantaOrange.png",
          title: "Fanta Orange",
          short_description: "33 Cl",
          price: "3.00",
        },
        {
          icon: "OasisTropical.png",
          title: "Oasis Tropical",
          short_description: "25 Cl",
          price: "3.00",
        },
        {
          icon: "SchweppesAgrum.png",
          title: "Schweppes Agrum",
          short_description: "25 Cl",
          price: "3.00",
        },
        {
          icon: "IceTeaPeche.png",
          title: "Ice Tea Peche",
          short_description: "25 Cl",
          price: "3.00",
        },
        {
          icon: "Jusdorange.png",
          title: "Jus d’orange",
          short_description: "25 Cl",
          price: "3.00",
        },
        {
          icon: "Orangina.png",
          title: "Orangina",
          short_description: "25 Cl",
          price: "3.00",
        },
        {
          icon: "Eaupétillante.png",
          title: "Eau pétillante",
          short_description: "75 Cl",
          price: "3.00",
        },
        {
          icon: "Eauplate.png",
          title: "Eau plate",
          short_description: "75 Cl",
          price: "3.00",
        },
        {
          icon: "CaféNespresso.png",
          title: "Café Nespresso",
          short_description: "expresso , lungo , ristretto",
          price: "1.50",
        },

        {
          icon: "ThémasalaIndien.png",
          title: "Thé masala Indien",
          short_description: ".",
          price: "3.00",
        },
      ]

    },

    {
      category_icon: "icon7.png",
      category_title: "DESSERT",
      category_tagname: ".",
      category_slug : "drinks",
      items: [
        {
          icon: "FaloodaPistache.png",
          title: "Falooda Pistache",
          short_description: "        .",
          price: "5.50",
        },
        {
          icon: "Cheesecake.png",
          title: "Cheesecake",
          short_description: "  .           ",
          price: "4.00",
        },
        {
          icon: "Kulfi.png",
          title: "Kulfi",
          short_description: "Glace indienne faite maison, parfumée à la pistache, à la cardamome, aux extraits secs de lait et aux noix",
          price: "5.50",
        },
        {
          icon: "Glace2boulesauchoix.png",
          title: "Glace 2 boules au choix",
          short_description: "Fraise , Pistache , Vanille , Chocolat",
          price: "4.50",
        },
        {
          icon: "FondantChocolat.png",
          title: "Fondant Chocolat ( servi avec glace vanille et crème anglaise )",
          short_description: "     .            ",
          price: "4.50",
        }
        
      ]

    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
