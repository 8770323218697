import { Component, OnInit } from '@angular/core';

import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    dots: false,
    margin:0,
    nav:true,
    autoplay:true,
    autoplaySpeed: 2000,
    navSpeed: 2000,
    items: 1,
    
    navText: ['<i class="la la-angle-left"></i>', '<i class="la la-angle-right"></i>'],
    /* responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    }, */
  }

  slideStore = [
    {
      introtext: "",
      title: "TAMI",
      subtitle: "LA CUISINE INDIENNE QUE TOUT LE MONDE AIME",
      image: "assets/images/main-slider/slide1.jpg",
    },

    {
      introtext: "",
      title: "Le Restaurant Indien",
      subtitle: "LE MEILLEUR SAVEUR INDIEN A PESSAC",
      image: "assets/images/main-slider/slide2.jpg",
    }

  ]
  constructor() { }

  ngOnInit(): void {
  }

}
