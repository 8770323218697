<!-- About Us -->
<div class="section-full content-inner bg-pink-light">
    <div class="container">
        <div class="row">
            <div class="col-md-6 m-b30">
                <img class="radius-sm img-cover" src="assets/images/about/pic11.jpeg" alt="">
            </div>	
            <div class="col-md-6 m-b30 align-self-center">
                <div class="section-head full-head text-center">
                    <h2 class="main-title text-primary">À PROPOS DE NOUS</h2>
                    <h3 class="title">TAMI</h3>
                    <div class="dlab-separator"></div>
                    <p>Spécialisé dans la gastronomie indienne, Tami est un restaurant authentique de la culture culinaire indienne.
                        Avec nos curry maison, nos savoureux entrées, les assortiments de pains et desserts authentiques indiens, nous vous invitons à venir profiter du vrai goût d’Inde à Bordeaux.
                        En utilisant les meilleurs ingrédients, nous priorisons sur la création de plats indiens sains et de haute qualité avec une faible teneur en huile afin de satisfaire le palet français.</p>

                        <h5>A propos du Chef : Chef Rameshwar Kulkarni</h5>
                        <p>"Métaphoriquement parlant, nous sommes spécialisés dans les plats exotiques indiens" 
                            préparés sous la direction habile de notre Chef Rameshwar Kulkarni.
                            Chef indien au style moderne, il a commencé son voyage culinaire de façon professionnel à Londres en 2010. Il a travaillé dans plusieurs restaurant haut de gamme de Londres avant de s’installer à Bordeaux où il est venu apprendre sur la culture du vin et de la gastronomie française.
                            Sa curiosité et sa persévérance l’ont amené à découvrir comment les recettes indiennes se marient bien avec le vin de Bordeaux. Il a également publié un livre sur ce sujet.</p>
                </div>
                <div class="rating-box text-center">
                    <h2 class="rating-title">Nos recommandations</h2>
                    <ul class="rating-star">
                        <li><i class="fa fa-star text-primary"></i></li>
                        <li><i class="fa fa-star text-primary"></i></li>
                        <li><i class="fa fa-star text-primary"></i></li>
                        <li><i class="fa fa-star text-primary"></i></li>
                        <li><i class="fa fa-star text-primary"></i></li>
                        <!-- <li><i class="fa fa-star text-gray-dark"></i></li> -->
                    </ul>
                    
                    <h4 class="rating-name">Mix Grill / Assortiments de grillades</h4>
                    <p>Un assortiment tout droit sorti du tandoor composé de différentes viandes marinées dans notre mélange d'herbes et d'épices indiennes exotiques. Idéal pour une entrée</p>
                    
                    <h4 class="rating-name">Thali</h4>
                    <p>Combinaison de saveurs du sol indien avec notre touche magique de modernité. Profitez de l’association de différents assortiments mis en place pour remplir votre ventre comme votre âme.
                        Comprenant tous les éléments nutritionnels avec une entrée, une salade, un plat ainsi qu'un dessert.</p>
                        <h4 class="rating-name">Décoration</h4>
                        <p>Inspiré d'une atmosphère moderne et d'une ambiance jeune et fraîche, nous vous offrons, chez Tami, une expérience vibrante lors de votre visite dans notre restaurant.
                            Rempli d'un éclairage chaleureux et d'un environnement lumineux, le lieu offre une pleine valeur pour votre temps.</p>
                       
                </div>
            </div>
        </div>
    </div>
</div>
<!-- About Us END -->
