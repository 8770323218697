import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError, retry } from 'rxjs/operators';
//import {ApiUrls}from  '../../../../shared/ApiConstants'
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import{environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReservationFormService {

  result: any[];
  private apiUrl = environment.apiEndpoint + "/api/Payment/";


  constructor(private http: HttpClient) { }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
        // Client-side errors
        errorMessage = `Error: ${error.error.message}`;
    } else {
        // Server-side errors
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  sendMail(strName,strEmail,strPerson,strDate,strTime,strMessage): Observable<any[]> {
   // alert(JSON.stringify(1))
    const url = environment.apiEndpoint + 'Agm/Qc/sendMail?strName='+strName+'&strEmail=' + strEmail+'&strPerson=' + strPerson+'&strDate=' + strDate+'&strTime=' + strTime+'&strMessage=' + strMessage;
   // alert(JSON.stringify(url))
    return this.http.get<any[]>(url)
        .pipe(
        catchError(this.handleError)
        )
  }

  /* sendMail(): any {

    const headers = { 'content-type': 'application/json' }
    const url = environment.apiEndpoint + 'Agm/Qc/sendMail';
    let InvoiceModel = { InvoiceHeader, InvoiceDetail, DatCstr, UserId, CompanyId, CompanyCode};
    return this.http.post<any>(url,  { 'headers': headers })
        .pipe(
        map((savegrp) => {
            return savegrp;
        }),
        catchError(this.handleError)
        )

} */


}
